/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeWithoutIconDto } from './codeWithoutIconDto.model';
import { RankAddition } from './rankAddition.model';


export interface PersonMilitarySuperiorDataDto { 
    firstName?: string;
    lastName?: string;
    gender?: PersonMilitarySuperiorDataDtoGenderEnum;
    preferredEmailAddress?: string;
    preferredPhoneNumber?: string;
    rank?: CodeWithoutIconDto;
    rankAddition?: RankAddition;
}
export enum PersonMilitarySuperiorDataDtoGenderEnum {
    M = 'M',
    F = 'F'
};



