<div class="tw-flex tw-gap-18 tw-p-15" [class]="bgColor()" [class.tw-hidden]="dismissed">
  <div class="tw-flex tw-flex-grow-0 tw-flex-row tw-gap-9">
    <div class="!tw-size-21 tw-flex-grow-0 d:!tw-size-24">
      <mat-icon class="tw-text-white" [svgIcon]="icon()" />
    </div>

    <div class="tw-flex tw-flex-col">
      @if (contentNoticeTitle(); as titleText) {
        <p class="msa-text tw-mb-9 tw-flex-grow">{{ titleText | translate }}</p>
      }

      <div class="msa-text-small">
        <ng-content />
      </div>
    </div>

    @if (isDismissible()) {
      <div class="tw-flex-shrink-0">
        <button (click)="dismiss()">
          <mat-icon class="!tw-size-21 tw-text-white d:!tw-size-24" svgIcon="close" />
        </button>
      </div>
    }
  </div>

  <div class="tw-flex-grow-1">
    <ng-content select="[noticeImage]" />
  </div>
</div>
