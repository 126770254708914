/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeDto } from './codeDto.model';


export interface LanguageSkillsDto { 
    language?: CodeDto;
    skillLevelTalk?: LanguageSkillsDtoSkillLevelTalkEnum;
    skillLevelRead?: LanguageSkillsDtoSkillLevelReadEnum;
    skillLevelWrite?: LanguageSkillsDtoSkillLevelWriteEnum;
    motherTongue?: boolean;
    translator?: boolean;
}
export enum LanguageSkillsDtoSkillLevelTalkEnum {
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH'
};
export enum LanguageSkillsDtoSkillLevelReadEnum {
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH'
};
export enum LanguageSkillsDtoSkillLevelWriteEnum {
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH'
};



