import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { MsaPersonDataConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AgreementRestService } from './api/agreement.service';
import { CodeRestService } from './api/code.service';
import { DutyRestService } from './api/duty.service';
import { FeatureFlagRestService } from './api/featureFlag.service';
import { MilitaryRestService } from './api/military.service';
import { NotificationRestService } from './api/notification.service';
import { PersonRestService } from './api/person.service';
import { PreferencesRestService } from './api/preferences.service';
import { SwissPassRestService } from './api/swissPass.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AgreementRestService,
    CodeRestService,
    DutyRestService,
    FeatureFlagRestService,
    MilitaryRestService,
    NotificationRestService,
    PersonRestService,
    PreferencesRestService,
    SwissPassRestService ]
})
export class MsaPersonDataApiModule {
    public static forRoot(configurationFactory: () => MsaPersonDataConfiguration): ModuleWithProviders<MsaPersonDataApiModule> {
        return {
            ngModule: MsaPersonDataApiModule,
            providers: [ { provide: MsaPersonDataConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: MsaPersonDataApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('MsaPersonDataApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
